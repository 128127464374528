import $ from "jquery";

function abbreviate(length=22, first_length=undefined) {
    const br = first_length !== undefined ? first_length : length / 2;
    return (data, type, _full, _meta) => {
        if (type === "display" && data.length > length)
            return `<abbrv title='${data}'>${data.slice(0, br)}...${data.slice(-(length - br - 1))}</abbr>`;
        return data;
    };
}

function getCurrentUTCOffsetWithMinutes(timeZone) {
  const now = new Date();

  // Format the date in the specified time zone and extract offset
  const formatter = new Intl.DateTimeFormat('en-US', {
    timeZone,
    timeZoneName: 'short',
  });

  // Use the formatToParts to get the GMT offset (e.g., "GMT+01" or "GMT+02")
  const offsetString = formatter.formatToParts(now)
    .find(part => part.type === 'timeZoneName').value;

  // Extract the hours and minutes from the offset (e.g., "+01" or "+02")
  const match = offsetString.match(/GMT([+-])(\d{1,2})(?::(\d{2}))?/);

  if (match) {
    const sign = match[1]; // "+" or "-"
    const hours = match[2].padStart(2, '0'); // Ensure 2-digit hours
    const minutes = match[3] ? match[3].padStart(2, '0') : '00'; // Ensure 2-digit minutes

    return `${sign}${hours}:${minutes}`;
  }

  throw new Error(`Unable to determine offset for time zone: ${timeZone}`);
}

function format_date(rawDate, abbrv=false) {
    const date = new Date(`${rawDate}${getCurrentUTCOffsetWithMinutes("Europe/Paris")}`);

    if (isNaN(date)) return "";

    const fullDate = date
        .toLocaleString("en-CA", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
        })
        .replace(",", "");

    if (!abbrv) return fullDate;

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const isBeforeToday = date.getTime() < today.getTime();
    const formattingOptions = isBeforeToday
        ? {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
          }
        : {
              hour: "2-digit",
              minute: "2-digit",
              hour12: false,
          };
    const formattedDate = date.toLocaleString("en-CA", formattingOptions);
    return `<span class="mr-auto" title="${fullDate}">${formattedDate}</span>`;
}

function render_date(field) {
    return (data, type, full) => {
        const d = field === undefined ? data : full[field];
        return type === "display" ? format_date(d, true) : d;
    };
}

function render_array(separator="<br>") {
  return (d, t, _r) => {
    if (t === "display" && d !== undefined)
      return d.join(separator);
    return d;
  };
}

function before(days=30) {
    const d = new Date();
    d.setDate(new Date().getDate() - days);
    return d.toISOString();
}

function render_user_status(_, type, data) {
  if (type === "sort") return data.confirmed + data.admin * 2;
  if (type !== "display") return data;
  if (data.admin === 1)
        return '<span class="fa fa-star" title="Admin"></span>';
    if (data.confirmed === 0)
        return '<span class="fa fa-exclamation-circle" title="Unconfirmed"></span>';
    if (data.punishement > 0)
        return '<span class="fa fa-bolt" title="Punished"></span>';
    if (data.alpha > 0)
        return '<span class="fa fa-star-half" title="Alpha"></span>';
    if (data.beta > 0) return '<span class="fa fa-flask" title="Beta"></span>';
    return "";
}

const job_status = [
    { label: "created", icon: "fa fa-stop-circle", color: "text-primary" },
    { label: "submitted", icon: "fa fa-circle-notch", color: "text-info" },
    { label: "prepared", icon: "fa fa-dot-circle", color: "text-info" },
    { label: "started", icon: "fa fa-play-circle", color: "text-primary" },
    { label: "passed", icon: "far fa-check-circle", color: "text-success" },
    { label: "failed", icon: "fa fa-exclamation-circle", color: "text-danger" },
    { label: "finished", icon: "fa fa-check-circle", color: "text-success" },
    {
        label: "retrieved",
        icon: "fa fa-arrow-circle-down",
        color: "text-success",
    },
    { label: "removed", icon: "fa fa-trash", color: "text-secondary" },
];

const JOB_REMOVED_PURGED_VALUE = 3;

function render_job_status(data, type, row) {
    const status = job_status[data] ?? {
        label: "unknown",
        icon: "fa fa-question",
        color: "text-danger",
    };

    const title = `Job ${row.purged === JOB_REMOVED_PURGED_VALUE ? "removed" : status.label}`;
    const icon =
        row.purged === JOB_REMOVED_PURGED_VALUE ? "fa fa-trash" : status.icon;

    return type === "display"
        ? `<span class="job-status btn-sm btn-outline-default" data-toggle="tooltip" title="${title}"><i class="${status.color} ${icon} fa-2x"></i></span>`
        : status;
}


function render_metainfos(showshared = false) {
    return (data, type, full) => {
        let files = [];
        const props = [];
        try {
            const options = JSON.parse(data);
            for (const prop in options) {
                if (
                    Object.hasOwn(options, prop) &&
                    options[prop] !== "" &&
                    prop !== "files"
                ) {
                    props.push(`${prop}: ${options[prop]}`);
                }
            }
            files = options.files;
        } catch {} /* eslint-disable-line no-empty */
        if (type !== "display") return files.join(" ");
        const html = [];
        const clz =
            !showshared || full.shared
                ? "btn-outline-info"
                : "btn-outline-danger";
        html.push("<div class='btn-group'>");
        if (props.length > 0)
            html.push(
                $('<span data-toggle="tooltip"><i class="fa fa-info">')
                    .addClass(`btn btn-sm ${clz}`)
                    .data({ toggle: "tooltip", html: true })
                    .attr("title", props.join("\n"))
                    .prop("outerHTML"),
            );
        html.push(
            files
                .map((file, idx) =>
                    $(`<span><i class="far fa-file ">`)
                        .addClass(`btn btn-sm ${clz} editor_nii`)
                        .attr("data-toggle", "tooltip")
                        .attr("data-fileid", idx)
                        .attr("title", file)
                        .prop("outerHTML"),
                )
                .join("\n"),
        );
        html.push("</div>");
        return html.join("\n");
    };
}

function render_btn_group(buttons, baseClass, prefixAction, className) {
    const btns = buttons.map((v) =>
        $.extend(
            {
                action: v.action,
                title: v.action.replace(/[-_]/g, " "),
                className: v.className || className || "btn-default",
                icon: v.action,
                text: "",
            },
            v,
        ),
    );

    return (_, type) => {
        if (type !== "display") return null;
        const html = [];
        html.push("<div class='btn-group'>");
        for (const btn of btns) {
            html.push(
                `<button class="btn ${baseClass || "btn-sm"} ${btn.className} ${prefixAction}${btn.action}" title="${btn.title}" data-toggle="tooltip">`,
            );
            html.push(`<span class="fa fa-${btn.icon}"></span> ${btn.text}`);
            html.push("</button>");
        }
        html.push("</div>");
        return html.join("");
    };
}

function render_guarded_btn_groups(
    triggers,
    baseClass,
    prefixAction,
    className,
) {
    const callbacks = triggers.map((v) =>
        v[0] instanceof Function
            ? [
                  v[0],
                  render_btn_group(
                      v.slice(1),
                      baseClass,
                      prefixAction,
                      className,
                  ),
              ]
            : [
                  () => true,
                  render_btn_group(v, baseClass, prefixAction, className),
              ],
    );

    return (data, type, full, meta) => {
        for (const cb of callbacks) {
            if (cb[0](data, full)) return cb[1](data, type, full, meta);
        }
        return "";
    };
}

export {
    format_date,
    render_date,
    render_array,
    render_metainfos,
    render_job_status,
    render_guarded_btn_groups,
    render_user_status,
    abbreviate,
    render_btn_group,
    before,
};
