import $ from "jquery"

export class PageAction {
    DEBUG = true;
    COUNTER = 0;
    constructor(view, config) {
        PageAction.COUNTER++;
        this.config = config ?? {};
        if (Object.hasOwn(this.config, "name")) {
            this.name = this.config.name;
        } else {
            this.name = `action${PageAction.COUNTER}`;
        }
        if (Object.hasOwn(this.config, "create")) {
            this.create = this.config.create.bind(this);
        }
        if (Object.hasOwn(this.config, "update")) {
            this.update = this.config.update.bind(this);
        }
        if (Object.hasOwn(this.config, "render")) {
            this.render = this.config.render.bind(this);
        }
        this.create(view, this.config);
    }
    create(view, config) {
        if (PageAction.DEBUG) {
            console.log(
                `create name: ${this.name}, view: ${JSON.stringify(view)}, data: ${JSON.stringify(config)}`,
            );
        }
    }
    update(view, payload) {
        if (PageAction.DEBUG) {
            console.log(
                `update name: ${this.name}, view: ${JSON.stringify(view)}, data: ${JSON.stringify(payload)}`,
            );
        }
    }
    render(view) {
        if (PageAction.DEBUG) {
            console.log(
                `render name: ${this.name}, view: ${JSON.stringify(view)}`,
            );
        }
    }
}

class PageView {
    constructor(elem, config) {
        this.config = config ?? {};
        if (typeof elem === "string") {
            this.root = elem;
            this.elem = $(this.root);
        } else {
            this.root = null;
            this.elem = elem;
        }
        this.template = this.config.template ?? null;
        this.configs = this.config.configs ?? {};
        this.actions = this.config.actions ?? {};
        if (this.template != null) {
            $(this.elem).html(this.template);
        }
        const actionNames = Object.keys(this.actions);
        for (let actionId = 0; actionId < actionNames.length; actionId++) {
            const actionName = actionNames[actionId];
            this.actions[actionName] = new PageAction(
                this,
                this.actions[actionName],
            );
        }
        this.currentAction = null;
        if (Object.hasOwn(this.config, "current")) {
            console.log(this.config.current)
            this.dispatch(this.config.current, this.config.payload ?? {})
        }
    }
    dispatch(actionName, payload) {
        if (
            actionName === undefined ||
            actionName === null ||
            actionName === ""
        ) {
            console.log(`action name not allowed: ${actionName}`);
            return false;
        }
        if (Object.hasOwn(this.actions, actionName)) {
            this.action = this.actions[actionName];
            if (PageAction.DEBUG) {
                console.log(`dispatch: ${actionName}`);
            }
            if (Object.hasOwn(this.action, "update")) {
                this.action.update(this, payload);
            }
            if (Object.hasOwn(this.action, "render")) {
                this.action.render(this);
            }
            return true;
        }
        console.log(`action name not recognized: ${actionName}`);
        return false;
    }
}


$.fn.pageView = function(options) {
    return new PageView(this[0], options)
}
